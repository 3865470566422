import React from 'react';
import {
  generatePath, useParams, Navigate, Outlet
} from 'react-router-dom';
import { availableLanguages, defaultLanguage } from './constants';

export const LanguageGuard = (): React.ReactElement => {
  const { lang = '' } = useParams<{ lang: string }>();

  if (!availableLanguages.includes(lang)) {
    return <Navigate to={generatePath('/:lang', { lang: defaultLanguage })} replace />;
  }

  return <Outlet />;
};
