import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { defaultLanguage } from '../constants';

export type UseLanguageResult = {
  lang: string;
  t: TFunction;
};

export const useLanguage = (): UseLanguageResult => {
  const { lang = defaultLanguage } = useParams<{ lang: string }>();
  const { t } = useTranslation();

  return {
    lang,
    t
  };
};
