import React from 'react';
import { createInstance } from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next, I18nextProvider } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { defaultLanguage } from './constants';

interface Props {
  children: React.ReactElement;
}

const i18n = createInstance({
  fallbackLng: defaultLanguage,
  load: 'languageOnly',
  debug: true, // @TODO remove from production build
  detection: {
    order: ['path']
  }
});

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init();

export const LanguageProvider = ({ children }: Props): React.ReactElement => (
  <I18nextProvider i18n={i18n}>
    {children}
  </I18nextProvider>
);
