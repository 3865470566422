import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ThemeProvider } from '@mui/material/styles';
import { LanguageProvider } from './containers/Language';
import { UserAuthProvider } from './containers/UserAuth';
import theme from './theme';

interface Props {
  children: React.ReactElement,
}

const queryClient = new QueryClient();

const Providers = ({ children }: Props): React.ReactElement => (
  <UserAuthProvider>
    <QueryClientProvider client={queryClient}>
      <LanguageProvider>
        <ThemeProvider theme={theme}>
          {children}
        </ThemeProvider>
      </LanguageProvider>
    </QueryClientProvider>
  </UserAuthProvider>
);

export default Providers;
