import { JwtPayload, jwtDecode } from 'jwt-decode';
import { AuthContextProps, useAuth } from 'oidc-react';
import { Role } from '../../../../types';

export type UserTokenData = {
  'realm_access': {
    'roles': string[];
  };
  'tenant': string[];
} & JwtPayload;

export type HelperProps = {
  decodedToken?: UserTokenData | null;
  roles: string[];
  userTenants: string[];
  isSuperAdmin: boolean;
  isSingleTenant: boolean;
};
export type UseUserAuthResult = AuthContextProps & HelperProps & NonNullable<unknown>;

export const useUserAuth = (): UseUserAuthResult => {
  const { userData, ...rest } = useAuth();

  const decodedToken = userData?.access_token ? jwtDecode(userData.access_token) as UserTokenData : null;
  // This will contain important roles like super-admin, admin, etc.
  const roles = decodedToken?.realm_access.roles ?? [];

  const userTenants = decodedToken?.tenant ?? [];

  const isSuperAdmin = roles.includes(Role.SUPER_ADMIN);
  const isSingleTenant = userTenants.length === 1;

  return {
    userData,
    userTenants,
    decodedToken,
    roles,
    isSuperAdmin,
    isSingleTenant,
    ...rest
  };
};
