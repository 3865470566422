import React from 'react';
import { Outlet } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { useUserAuth } from '../../hooks/useUserAuth';
import { LoaderBox } from './UserAuthGuard.styles';

export const UserAuthGuard = (): React.ReactElement => {
  const { userData } = useUserAuth();

  if (!userData) {
    return (
      <LoaderBox>
        <CircularProgress />
      </LoaderBox>
    );
  }

  return <Outlet />;
};
