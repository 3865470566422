import { User } from 'oidc-client-ts';

export const getUserFromStorage = (): User | null => {
  const authUrl = process.env.REACT_APP_AUTH_URL;
  const authClientId = process.env.REACT_APP_AUTH_CLIENT_ID;
  const oidcStorage = sessionStorage.getItem(`oidc.user:${authUrl}:${authClientId}`);
  if (!oidcStorage) {
    return null;
  }

  return User.fromStorageString(oidcStorage);
};
