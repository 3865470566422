const getEnvironment = () => {
  const { hostname } = window.location;

  if (hostname.includes('localhost')) {
    return 'local';
  }
  if (hostname.includes('develop.autotwin.com')) {
    return 'develop';
  }
  if (hostname.includes('staging.autotwin.com')) {
    return 'staging';
  }
  if (hostname === 'autotwin.com' || hostname.endsWith('.autotwin.com')) {
    return 'production';
  }
  return hostname;
};

export default getEnvironment;
