import React from 'react';
import { AuthProvider, AuthProviderProps } from 'oidc-react';
import { authority, clientId, keycloakSearchParams } from '../../constants';

type UserAuthProviderProps = {
  children: React.ReactElement;
};

const authConfig: AuthProviderProps = {
  authority,
  clientId,
  onSignIn: () => {
    const searchParams = new URLSearchParams(window.location.search);

    keycloakSearchParams.forEach((p) => searchParams.delete(p));

    window.location.search = searchParams.toString();
  },
  redirectUri: window.location.href
};

export const UserAuthProvider: React.FC<UserAuthProviderProps> = ({ children }: UserAuthProviderProps) => (
  <AuthProvider {...authConfig}>
    {children}
  </AuthProvider>
);
