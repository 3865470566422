import React, { Suspense, useEffect } from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Route
} from 'react-router-dom';
import { ProductFruits } from 'react-product-fruits';
import posthog from 'posthog-js';
import Hotjar from '@hotjar/browser';
import { mouseflow } from 'react-mouseflow';
import { RootRoutes } from '../pages/root.routes';
import ErrorPage from '../pages/ErrorPage';
// import { useLanguage } from '../containers/Language';
import { useUserAuth } from '../containers/UserAuth';
import getEnvironment from '../util/getEnvironment';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="*" element={<RootRoutes />} errorElement={<ErrorPage />} />
  )
);

const App = (): React.ReactElement => {
  // const { t } = useLanguage();
  const { userData, roles, userTenants } = useUserAuth();

  const userInfo = userData && {
    username: userData.profile.email!,
    email: userData.profile.email!,
    firstname: userData.profile.given_name!,
    lastname: userData.profile.family_name!
  };

  useEffect(() => {
    if (userData && userInfo) {
      posthog.identify(userInfo.username, {
        email: userInfo.email,
        firstname: userInfo.firstname,
        lastname: userInfo.lastname,
        roles,
        userTenants
      });

      Hotjar.identify(userInfo.username, {
        email: userInfo.email,
        firstname: userInfo.firstname,
        lastname: userInfo.lastname
      });
    }
  }, [userData]);

  useEffect(() => {
    mouseflow.initialize('2f41c207-9f7e-4ae1-8261-1ffe0ecadb1e');
  }, []);

  const environment = getEnvironment();

  return (
    <Suspense fallback={<p data-testid="loader">loading...</p>}>
      {userInfo && environment !== 'local' && environment !== 'develop' && (
        <ProductFruits
          workspaceCode="0sViyTe8FHjpDari"
          language="de"
          user={userInfo}
        />
      )}
      <RouterProvider router={router} />
    </Suspense>
  );
};

export default App;
