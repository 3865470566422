import { createTheme } from '@mui/material/styles';
import MNKYKlausRegular from './assets/fonts/MNKYKlaus-Regular.woff2';

export default createTheme({
  palette: {
    primary: {
      main: '#3363F7'
    },
    secondary: {
      main: '#FEFEFE'
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
                @font-face {
                    font-family: 'MNKYKlaus';
                    font-style: normal;
                    font-display: swap;
                    font-weight: 400;
                    src:
                        local('Roboto'),
                        url(${MNKYKlausRegular}) format('woff2');
                }
          `
    }
  },
  typography: {
    fontFamily: 'MNKYKlaus'
  }
});
